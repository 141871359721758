#view_payment-methods {
    
    ._block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        margin-bottom: 1px;

        ._title-box {
            margin-bottom: 0;
        }

        ._action {
            margin-left: 0;
            width: $percent-50;
            text-align: right;
            padding-right: 15px;

            .btn-add-payment {
            font-size: 12px;
            font-weight: $fw700;
            color: $color-white;
            font-family: $font-comfortaa;
            padding: 0.375rem 0.75rem;
            max-width: 122px;

                svg {
                    path {
                    fill: $color-white;
                    }
                }
            }
        }
    }

    ._columns {

        ._column {

            background-color: $color-white;
            padding-bottom: .9375rem;
            padding-top: 15px;

            ._block {

                .payment-card {
                    // padding: 0.769rem 0.9375rem;
                    padding-right: 0;
                    padding-left: 0;
                    margin: 0.9375rem;
                    margin-top: 0;
                    margin-bottom: 0.625rem;
                    -webkit-box-shadow: 0 4px 6px 1px rgba(200,200,200,0.1);
                    box-shadow: 0 4px 6px 1px rgba(200,200,200,0.1);
                    border: 1px solid #F9F9F9;
                    border-radius: 4px;
                    background-color: $color-white;

                    ._cols {

                        align-items: center;
                        justify-content: space-between;

                        ._col {

                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: $percent-100;

                            button.payment-action {
                                max-width: 45px;
                                background-color: $color-white;
                                padding: 0.469rem 0.9375rem;

                                &:focus,
                                &:active {
                                    background-color: $color-white !important;
                                }

                                svg {
                                    width: auto;
                                    height: 14px;

                                    path {

                                        fill: $color-blue ;
                                    }
                                }
                            }

                            .actions {

                                display: flex;

                                button.payment-action {
                                    max-width: 45px;
                                    background-color: $color-white;
                                    padding: 0.469rem 0.9375rem;

                                    &:focus,
                                    &:active {
                                        background-color: $color-white !important;
                                    }

                                    svg {
                                        width: auto;
                                        height: 16px;

                                        path {

                                            fill: $color-blue ;
                                        }
                                    }
                                }
                            }

                            .payment-preview-info {

                                display: flex;
                                width: $percent-100;

                                .icon {
                                    padding: .500rem;
                                    width: 56px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        height: auto;
                                        width: 26px;
                                    }
                                }
                            }

                            span.tag {
                                font-family: $font-nunito;
                                color: $color-blue;
                                font-weight: $fw600;
                                font-size: .750rem;
                                background-color: $color-ice;
                                padding: .100rem .850rem;
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                display: inline-block; 
                            }

                            button.payment-action {
                                background-color: transparent;
                                max-width: 40px;

                                &:focus,
                                &:active {
                                background-color: $color-white !important;
                                }
                            }

                            &:last-child {
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }
}